import React from "react";
import { CSSTransition } from "react-transition-group";

const PrivacyPolicy = () => {
  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={1000}
      classNames="fade"
      unmountOnExit
    >
      <div className="w-full min-h-screen overflow-hidden relative bg-black">
        <CSSTransition
          in={true}
          appear={true}
          timeout={1000}
          classNames="content-fade"
          unmountOnExit
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-[90%] h-[90%] overflow-y-auto text-white p-8">
              <div className="max-w-4xl mx-auto space-y-6">
                <h1 className="text-4xl font-bold">Privacy Policy</h1>
                <p>Last Updated: 19th July 2024</p>

                <section>
                  <h2 className="text-2xl font-semibold">1. Introduction</h2>
                  <p>
                    Welcome to Mythyaverse Private Limited. We are committed to
                    protecting your privacy and ensuring a safe online
                    experience. This Privacy Policy outlines how we collect,
                    use, disclose, and protect your personal information when
                    you use our AI-based interview web application, VRPlaced.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    2. Information We Collect
                  </h2>
                  <p>Personal Information:</p>
                  <p>
                    Identification Details: Name, email address, contact
                    information, job application details, and any other
                    information you provide when you create an account or apply
                    for a job through our platform.
                  </p>

                  <p>Sensitive Personal Data or Information (SPDI):</p>
                  <p>Media: Video and audio recordings of your interviews.</p>
                  <p>
                    Responses: Answers to interview questions and other input
                    provided during the interview process.
                  </p>
                  <p>
                    AI Analysis: AI-generated insights and analytics based on
                    your interview performance.
                  </p>

                  <p>Usage Data:</p>
                  <p>
                    Technical Data: br/owser type, IP address, device
                    information, and operating system.
                  </p>
                  <p>
                    Interaction Data: Date and time of visits, pages viewed, and
                    other interaction details.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    3. How We Use Your Information
                  </h2>
                  <p>We use the collected information for various purposes:</p>
                  <p>
                    Service Delivery: To provide, operate, and maintain our web
                    application.
                  </p>
                  <p>
                    User Experience: To personalize and improve your experience
                    on our platform.
                  </p>
                  <p>
                    Communication: To send updates, notifications, and other
                    relevant information related to the interview process.
                  </p>
                  <p>
                    Analysis: To analyze user behavior and enhance the
                    performance of our AI algorithms.
                  </p>
                  <p>
                    Compliance: To comply with legal obligations and enforce our
                    terms and policies.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    4. Data Sharing and Disclosure
                  </h2>
                  <p>We may share your information with:</p>
                  <p>
                    Employers/Recruiters: Your interview data and personal
                    information may be shared with potential employers or
                    recruiters for the purpose of evaluating your application.
                  </p>
                  <p>
                    Service Providers: Third-party service providers who assist
                    us in operating our application, provided they comply with
                    our data protection standards.
                  </p>
                  <p>
                    Legal Authorities: Authorities if required by law or to
                    protect our rights and property.
                  </p>
                  <p>
                    We do not sell, trade, or rent your personal information to
                    third parties.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">5. Data Security</h2>
                  <p>
                    We implement robust security measures to protect your
                    personal information from unauthorized access, alteration,
                    disclosure, or destruction. However, no method of
                    transmission over the internet or electronic storage is 100%
                    secure.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    6. Your Data Protection Rights
                  </h2>
                  <p>
                    Under Indian law, you have the following rights regarding
                    your personal data:
                  </p>
                  <p>
                    Access: The right to request copies of your personal data.
                  </p>
                  <p>
                    Rectification: The right to request correction of inaccurate
                    information.
                  </p>
                  <p>
                    Erasure: The right to request deletion of your personal
                    data, under certain conditions.
                  </p>
                  <p>
                    Restriction: The right to request restriction of processing
                    of your personal data, under certain conditions.
                  </p>
                  <p>
                    Objection: The right to object to our processing of your
                    personal data, under certain conditions.
                  </p>
                  <p>
                    Portability: The right to request transfer of your personal
                    data to another organization, or directly to you, under
                    certain conditions.
                  </p>
                  <p>
                    To exercise these rights, please contact us at
                    contact@mythyaverse.com.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">7. Consent</h2>
                  <p>
                    By using our web application, you consent to our collection,
                    use, and sharing of your personal data as described in this
                    Privacy Policy. You have the right to withdraw your consent
                    at any time by contacting us at contact@mythyaverse.com.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    8. Cookies and Tracking Technologies
                  </h2>
                  <p>
                    We use cookies and similar tracking technologies to track
                    activity on our web application and hold certain
                    information. You can instruct your br/owser to refuse all
                    cookies or to indicate when a cookie is being sent.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    9. Third-Party Links
                  </h2>
                  <p>
                    Our web application may contain links to third-party
                    websites. We are not responsible for the privacy practices
                    or the content of these websites.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    10. Grievance Redressal
                  </h2>
                  <p>
                    In compliance with the Information Technology Act, 2000 and
                    the rules made thereunder, the name and contact details of
                    the Grievance Officer are provided below:
                  </p>
                  <p>Grievance Officer: Vishal Pandey</p>
                  <p>Email: vishal@mythyaverse.com</p>
                  <p>
                    Address: GNEC, Knowledge Park-II, Greater Noida, Uttar
                    Pradesh, 201310
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">
                    11. Changes to This Privacy Policy
                  </h2>
                  <p>
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page and updating the "Last Updated" date.
                  </p>
                </section>

                <section>
                  <h2 className="text-2xl font-semibold">12. Contact Us</h2>
                  <p>
                    If you have any questions about this Privacy Policy, please
                    contact us at:
                  </p>
                  <p>Email: contact@mythyaverse.com</p>
                  <p>
                    Address: C-809, Rameshwar Orchid, Supertech Rameshwar
                    Orchid, Ghaziabad, Uttar Pradesh, 201010
                  </p>
                </section>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  );
};

export default PrivacyPolicy;
