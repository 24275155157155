import AlekhJohari from "../../assets/advisor-pictures/alekh-johari.jpg";
// import AnkushMittal from "../../assets/advisor-pictures/drankush.jpg";
// import ManiMadhukar from "../../assets/advisor-pictures/drmani.jpg";
import RameshShankarS from "../../assets/advisor-pictures/ramesh-shankar-s.jpg";
import AbhishekGupta from "../../assets/advisor-pictures/Abhishek Gupta.png";
import ParthaPratimRoy from "../../assets/team-pictures/Partha Pratim Roy.jpg";
import AnujGarg from "../../assets/team-pictures/anuj.png";
import AnmolGupta from "../../assets/team-pictures/Anmol Gupta.jpg";
import VishalPandey from "../../assets/team-pictures/Vishal Pandey.png";
import AvinashJain from "../../assets/team-pictures/avinash.png";
import ContactForm from "../ContactForm/ContactForm";
import GalaxyPortalLink from "../Galaxy/GalaxyPortalLink";
import linkedin from "../../assets/linkedin logo.png";

import GalaxySlider from "../GalaxySlider/GalaxySlider";
// import { Link } from "react-router-dom";
import MukeshMohania from "../../assets/advisor-pictures/Mukesh Mohania.png";
import RajKapoor from "../../assets/advisor-pictures/Raj Kapoor.png";
import PrasannaLohar from "../../assets/advisor-pictures/Prasanna Lohar.png";
import DrAnkushMittal from "../../assets/advisor-pictures/Dr. Ankush Mittal.png";
import DrManiMadhukar from "../../assets/advisor-pictures/Dr. Mani Madhukar.png";

const PageContent = [
  {
    title: "ABOUT",
    element: function () {
      return (
        <p>
          <span style={{ fontWeight: "bold" }}>Mission</span>
          <br />
          "Our mission is a dual pursuit of enlightenment and empowerment. With
          VRPlaced, we're transforming the educational landscape, merging
          learning with the captivating allure of virtual reality. EmpoVR is our
          commitment to wellness, offering a sanctuary within the digital sphere
          where productivity harmonizes with tranquility."
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>Vision</span>
          <br />
          "We envision a world where every individual can access the treasures
          of India's cultural wisdom in a space that transcends time—a sanctuary
          where the pursuit of knowledge and well-being is not just a promise
          but a daily reality. MythyaVerse is your gateway to this new era of
          discovery and growth."
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>The Essence of MythyaVerse</span>
          <br />
          "Derived from 'Mithya'—the Sanskrit for myth, and 'Verse'—short for
          universe, our name is a beacon guiding users to a world where the
          legendary and the futuristic coalesce. Our offerings, VRPlaced and
          EmpoVR, are the vehicles through which we deliver on the promise of a
          versatile, transformative experience."
        </p>
      );
    },
  },
  {
    title: "PRODUCTS",
    element: function () {
      const links = [
        {
          element: (
            <GalaxyPortalLink
              productName="VRPlaced"
              productID="VRPlaced"
              scale={0.6}
              colorOne="#7debfd"
              colorTwo="#f3f3f3"
            />
          ),
          caption: "VRPlaced",
          link: "/transition?target=VRPlaced",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="EmpoVR"
              productID="EmpoVR"
              scale={0.6}
              colorOne="#ff8600"
              colorTwo="#ffffff"
            />
          ),
          caption: "EmpoVR",
          link: "/transition?target=EmpoVR",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="Spiritual Wellness"
              productID="mentalHealth"
              scale={0.6}
              colorOne="#b7fe00"
              colorTwo="#469f77"
            />
          ),
          caption: "Spiritual Wellness",
          link: "/transition?target=mentalHealth",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="Blockchain as a Service"
              productID="BaaS"
              scale={0.6}
              colorOne="#b7fe00"
              colorTwo="#469f77"
            />
          ),
          caption: "Blockchain as a Service",
          link: "/transition?target=BaaS",
        },
      ];

      return (
        <div className="">
          <GalaxySlider items={links} />
        </div>
      );
    },
  },
  {
    title: "SERVICES",
    element: function () {
      const links = [
        {
          element: (
            <GalaxyPortalLink
              productName="AI Consultancy"
              productID="AIConsultancy"
              scale={0.6}
              colorOne="#469f77"
              colorTwo="#b7fe00"
            />
          ),
          caption: "AI Consultancy",
          link: "/transition?target=AIConsultancy",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="XR Consultancy"
              productID="XRConsultancy"
              scale={0.6}
            />
          ),
          caption: "XR Consultancy",
          link: "/transition?target=XRConsultancy",
        },
      ];

      return (
        <div className="">
          <GalaxySlider items={links} />
        </div>
      );
    },
  },
  {
    title: "TEAM",
    element: function () {
      const TeamMember = ({
        photo,
        name,
        designation,
        qualification,
        social,
      }) => {
        return (
          <div className="flex items-center lg:p-5 py-2 md:mb-0 mb-10">
            <img
              src={photo}
              className="rounded-full w-[100px] aspect-square"
              alt={`${name}'s photo`}
            />
            <div className="flex flex-col border-4 border-white border-t-0 border-l-0 rounded-br-3xl p-4">
              <div className="flex items-center">
                <h2 className="font-bold text-lg mr-2">{name}</h2>
                {social && (
                  <a href={social} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="LinkedIn" className="w-4 h-4" />
                  </a>
                )}
              </div>
              <p className="xl:w-64 lg:w-56 md:w-44 w-48 max-[375px]:w-26 max-[320px]:w-32 font-semibold">
                {designation}
              </p>
              <p className="xl:w-64 lg:w-56 md:w-44 w-48 max-[375px]:w-26 max-[320px]:w-32">
                {qualification}
              </p>
            </div>
          </div>
        );
      };

      return (
        <>
          <h1 className="text-4xl mb-5">Our Team</h1>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <TeamMember
              photo={AnmolGupta}
              name="Anmol Gupta"
              designation={"CEO"}
              qualification={"PhD, IIT Roorkee | RuG Netherlands"}
              social={"https://www.linkedin.com/in/anmolscd/"}
            />
            <TeamMember
              photo={VishalPandey}
              name="Vishal Pandey"
              designation={"CTO"}
              qualification={"PhD, IIT Roorkee"}
              social={"https://www.linkedin.com/in/vmyth/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <TeamMember
              photo={ParthaPratimRoy}
              name="Partha Pratim Roy"
              designation={"Mentor"}
              qualification={"Associate Professor, CSE, IIT Roorkee"}
              social={
                "https://www.linkedin.com/in/dr-partha-pratim-roy-ab1144238/"
              }
            />
            <TeamMember
              photo={AnujGarg}
              name="Anuj Garg"
              designation={"Technical Advisor"}
              qualification={"Vice President - Engineering, ZebPay"}
              social={"https://www.linkedin.com/in/anujkgarg/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <TeamMember
              photo={AvinashJain}
              name="Avinash Jain"
              designation={"Technical Advisor"}
              qualification={"Associate Director, Kyndryl"}
              social={"https://www.linkedin.com/in/avinash-jain-852b0021a/"}
            />
          </div>
        </>
      );
    },
  },
  {
    title: "ADVISORS",
    element: function () {
      const Advisor = ({ photo, name, designation, social }) => {
        return (
          <div className="flex items-center lg:p-3 py-2 mb-0">
            <img
              src={photo}
              className="rounded-full w-[85px] aspect-square"
              alt={name}
            />

            <div className="flex flex-col border-4 border-white border-t-0 border-l-0 rounded-br-3xl p-3">
              <div className="flex items-center">
                <h2 className="font-bold text-lg mr-2">{name}</h2>
                {social && (
                  <a href={social} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="LinkedIn" className="w-4 h-4" />
                  </a>
                )}
              </div>
              <p className="xl:w-44 lg:w-36 md:w-32 w-40 max-[375px]:w-24 max-[320px]:w-28 text-sm leading-tight">
                {designation}
              </p>
            </div>
          </div>
        );
      };
      return (
        <>
          <h1 className="text-4xl mb-2">Our Advisors</h1>
          <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
            <Advisor
              photo={MukeshMohania}
              name="Mukesh Mohania"
              designation="Professor (CSE) at IIIT Delhi | Ex Director, AI, Blockchain and Cognitive Analytics at IBM Research - Australia"
              social="https://www.linkedin.com/in/mukesh-mohania-4755606/"
            />
            <Advisor
              photo={RajKapoor}
              name="Raj Kapoor"
              designation="Founder, India Blockchain Alliance | Chairman | Global Alliance for Ethical AI"
              social="https://www.linkedin.com/in/indieblock/"
            />
            <Advisor
              photo={RameshShankarS}
              name="Ramesh Shankar S"
              designation="Chief Joy Officer: Hrishti.com | Ex. Executive Vice President & Head of HR (South Asia Cluster) - Siemens"
              social="https://www.linkedin.com/in/srameshshankar/"
            />

            <Advisor
              photo={AbhishekGupta}
              name="Abhishek Gupta"
              designation="Head of Human Resources ZebPay ET NOW's 'Young HR Leader of the Year' | SCMHRD, Pune"
              social="https://www.linkedin.com/in/abhishek-gupta-04905b29/"
            />
            <Advisor
              photo={DrAnkushMittal}
              name="Dr. Ankush Mittal"
              designation="Vice Chancellor COER University | Adjunct Professor IIT Mandi | Ex-Director Sharda University | Ex-Director Graphic Era | Founder Raman Classes"
              social="https://www.linkedin.com/in/drankushmittal/"
            />
            <Advisor
              photo={DrManiMadhukar}
              name="Dr. Mani Madhukar"
              designation="Program Manager IBM India | Adjunct Professor IGDTOW | Adjunct Professor Gururkal Kangdi"
              social="https://www.linkedin.com/in/manimadhukar/"
            />
            <Advisor
              photo={PrasannaLohar}
              name="Prasanna Lohar"
              designation="Chief Innovation Officer Diamante Blockchain | CEO at Block Stack | Founder - India Blockchain Forum"
              social="https://www.linkedin.com/in/prasannalohar/"
            />
            <Advisor
              photo={AlekhJohari}
              name="Alekh Johari"
              designation="Founder: Anemoi Solution | Creator: Show called 'The Future of WEB' | Creator: Metaverse Marathon"
              social="https://www.linkedin.com/in/alekh-johari/"
            />
            <div className="md:block hidden"></div>
          </div>
        </>
      );
    },
  },
  {
    title: "CONTACT",
    element: function () {
      return (
        <>
          <div
            style={{ height: "100vh", overflowY: "auto", paddingRight: "20px" }}
          >
            You can contact us at:
            <br />
            contact@mythyaverse.com | ceo@mythyaverse.com
            <br />
            MythyaVerse Private Limited
            <br />
            GNEC-IIT Roorkee
            <br />
            Plot No 20, Knowledge Park II, Greater Noida, Uttar Pradesh - 201310
            <br />
            <br />
            <div style={{ width: "80%" }}>
              <ContactForm />
            </div>
            <br />
            <div style={{ width: "100%", textAlign: "left" }}>
              Company Information:
              <br />
              MythyaVerse Private Limited
              <br />
              CIN: U62099UP2023PTC177754
              <br />
              Contact Number: +91-8369716839
              <br />
              Registered Address: 150, GF, NEW GANDHI NAGAR, Ghaziabad,
              Ghaziabad, Ghaziabad, Uttar Pradesh, India, 201001
              <br />
              Grievance Officer: Vishal Pandey, +91-9971510759,
              vishal@mythyaverse.com
              <br />
              <br />
              <div className="flex gap-4">
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-600 underline"
                >
                  Privacy Policy
                </a>
                <a
                  href="/terms-conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-600 underline"
                >
                  Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </>
      );
    },
  },
];

export default PageContent;
