import React from "react";
import { Link } from "react-router-dom";

const TermsConditions = () => {
  return (
    <div className="w-full min-h-screen bg-black overflow-x-hidden">
      <div className="w-[90%] mx-auto py-8">
        <div className="max-w-4xl mx-auto">
          <div
            className="text-white space-y-6 overflow-y-auto"
            style={{
              maxHeight: "calc(100vh - 60px)",
              scrollBehavior: "smooth",
            }}
          >
            <h1 className="text-4xl font-bold mb-6">Terms of Service</h1>

            <section>
              <h2 className="text-2xl font-semibold mb-3">Introduction</h2>
              <p>
                Welcome to VRPlaced, a service provided by MythyaVerse Private
                Limited ("we," "our," or "us"). By accessing or using our
                service, you agree to comply with and be bound by these Terms of
                Service ("Terms"). Please read these Terms carefully before
                using our service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                1. Acceptance of Terms
              </h2>
              <p>
                By accessing or using VRPlaced ("Service"), you agree to be
                bound by these Terms and our Privacy Policy. If you do not agree
                with these Terms, you should not use our service. We recommend
                that you review these Terms periodically for any changes.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">2. Use of Service</h2>
              <p>
                You agree to use VRPlaced only for lawful purposes and in
                accordance with these Terms. You agree not to use the service in
                any way that could damage, disable, overburden, or impair the
                service, or interfere with any other party's use and enjoyment
                of the service.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">3. User Accounts</h2>
              <p>
                To use certain features of VRPlaced, you may need to create an
                account. You agree to provide accurate and complete information
                when creating your account and to keep this information up to
                date. You are responsible for maintaining the confidentiality of
                your account login information and for all activities that occur
                under your account. You agree to notify us immediately of any
                unauthorized use of your account.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                4. Intellectual Property
              </h2>
              <p>
                All content and materials on VRPlaced, including but not limited
                to text, graphics, logos, and software, are the property of
                MythyaVerse Private Limited or its licensors and are protected
                by intellectual property laws. You may not use, reproduce, or
                distribute any content from VRPlaced without our express written
                permission. Unauthorized use may result in legal action.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">5. User Content</h2>
              <p>
                You retain ownership of any content you submit or upload to
                VRPlaced. However, by submitting content, you grant MythyaVerse
                Private Limited a worldwide, non-exclusive, royalty-free,
                transferable license to use, reproduce, distribute, prepare
                derivative works of, and display the content in connection with
                the service. You warrant that you own or have the right to use
                the content you submit.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">6. Termination</h2>
              <p>
                We reserve the right to suspend or terminate your access to
                VRPlaced at any time, without notice, for any reason, including
                if we believe you have violated these Terms. If you believe your
                account has been terminated unfairly, you may contact us to
                appeal the decision.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                7. Disclaimer of Warranties
              </h2>
              <p>
                VRPlaced is provided "as is" and "as available" without
                warranties of any kind, either express or implied. MythyaVerse
                Private Limited disclaims all warranties, including but not
                limited to, implied warranties of merchantability, fitness for a
                particular purpose, title, and non-infringement. We do not
                warrant that the service will be uninterrupted, secure, or
                error-free.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                8. Limitation of Liability
              </h2>
              <p>
                To the fullest extent permitted by law, MythyaVerse Private
                Limited shall not be liable for any indirect, incidental,
                special, consequential, or punitive damages, or any loss of
                profits or revenues, whether incurred directly or indirectly, or
                any loss of data, use, goodwill, or other intangible losses,
                resulting from:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>your use or inability to use the service;</li>
                <li>
                  any unauthorized access to or use of our servers and/or any
                  personal information stored therein;
                </li>
                <li>
                  any interruption or cessation of transmission to or from the
                  service;
                </li>
                <li>
                  any bugs, viruses, trojan horses, or the like that may be
                  transmitted to or through our service by any third party;
                </li>
                <li>
                  any errors or omissions in any content or for any loss or
                  damage incurred as a result of the use of any content posted,
                  emailed, transmitted, or otherwise made available through the
                  service;
                </li>
                <li>
                  the defamatory, offensive, or illegal conduct of any third
                  party.
                </li>
              </ul>
              <p className="mt-2">
                In no event shall MythyaVerse Private Limited's aggregate
                liability for all claims relating to the service exceed the
                amount paid by you to MythyaVerse Private Limited for the past
                three months for the service. These limitations do not exclude
                liability for death or personal injury caused by negligence.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                9. Indemnification
              </h2>
              <p>
                You agree to indemnify, defend, and hold harmless MythyaVerse
                Private Limited, its affiliates, and their respective officers,
                directors, employees, agents, and contractors from and against
                any and all claims, damages, obligations, losses, liabilities,
                costs, debt, and expenses (including but not limited to
                attorney's fees) arising from:
              </p>
              <ul className="list-disc pl-6 mt-2 space-y-2">
                <li>your use of and access to the service;</li>
                <li>your violation of any term of these Terms;</li>
                <li>
                  your violation of any third-party right, including without
                  limitation any intellectual property, property, or privacy
                  right;
                </li>
                <li>
                  any claim that your content caused damage to a third party.
                </li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">10. Refund Policy</h2>
              <p>
                We offer a 30-day no-questions-asked return policy. If you are
                not satisfied with our service within 30 days of your purchase,
                you can request a full refund. No returns will be accepted after
                30 days. For refund requests, please contact us by email at
                contact@mythyaverse.com. Please include your account details and
                reason for the refund request.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">11. Governing Law</h2>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws of India, without regard to its conflict of law
                provisions. You agree to submit to the exclusive jurisdiction of
                the courts located in Delhi, India, for the purpose of
                litigating all such claims or disputes.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">
                12. Changes to Terms
              </h2>
              <p>
                We reserve the right, at our sole discretion, to modify or
                replace these Terms at any time. If a revision is material, we
                will provide at least 30 days' notice prior to any new terms
                taking effect. By continuing to access or use our service after
                those revisions become effective, you agree to be bound by the
                revised terms. Notice will be given via email or by posting on
                our website.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold mb-3">13. Contact Us</h2>
              <p>
                If you have any questions about these Terms, please contact us
                by email at contact@mythyaverse.com. We will endeavor to respond
                to your queries promptly.
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
